
import { TYPES as HDRTYPES } from '../components/HDR'
import { TYPES as FREESYNCTYPES } from '../components/FreeSync'
import { TYPES as GSYNCTYPES } from '../components/GSYNC'


export default {
	'HDR': {
		label: 'HDR',
		snippet: true,
		check: p => !!p.hdr,
		render: p => HDRTYPES[p.hdr].name,
	},
	'G-SYNC': {
		label: 'G-SYNC',
		snippet: true,
		check: p => !!p.gsync,
		render: p => GSYNCTYPES[p.gsync].name,
	},
	'FreeSync': {
		label: 'FreeSync',
		snippet: true,
		check: p => !!p.freesync,
		render: p => FREESYNCTYPES[p.freesync].name,
	},
	'HDCP': {
		label: "HDCP",
		snippet: true,
		check: p => !!p.hdcp,
		render: () => "HDCP 2.2",
	},
	'curved': {
		label: 'curved',
		check: p => !!p.curveRadius && p.curveRadius > 0,
	},
	'Vesa-mount': {
		label: 'Vesa mount',
		snippet: true,
		check: p => Array.isArray(p.vesa) && !!p.vesa.length,
	},
	'picture-by-picture': {
		label: 'Picture by Picture',
		snippet: true,
		check: p => !!p.pbp
	},
	'KVM': {
		label: 'KVM switch',
		snippet: true,
		check: p => !!p.kvm
	},
	'speakers': {
		label: 'speakers',
		check: p => p.speakers && p.speakers !== 'no',
		render: ({speakers, speakersWatt}) => `${speakersWatt ? `${speakersWatt}W ` : ''}${speakers} speakers`
	},
	'backlight': {
		label: 'backlight',
		check: p => !!p.backlight && p.backlight !== 'no'
	},
	'pivot': {
		label: 'pivot',
		snippet: true,
		check: p => !!p.pivot && p.pivot > 0,
	},
}
