
import React from 'react'
import { Link } from 'gatsby'

import NavLink from './NavLink'
import Symbol from '../helpers/Symbol'
import Search from './Search'


export default function NavBar({ }) {

	return <nav id='Navbar'>

		<Link to='/' className='logoLink'>
			<svg className='Logo'
				height='1em' 
				title='Home page'		
				alt='hardware.compare'
				viewBox="0 0 556 66"
			>
				<use xlinkHref={`#logoSymbol`} />
			</svg>
		</Link>

		<ul id='NavbarCats'>
			<li>
				<NavLink to={`/monitors/`}>
					<Symbol id='monitor' /> Monitors
				</NavLink>
			</li>
			{/* <li>
				<a>
					<Symbol id='headphones' /> Headphones <em>coming soon</em>
				</a>
			</li> */}
		</ul>

		<Search />

	</nav>
}
