
import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { navigate } from 'gatsby'

import NavLink from './NavLink'
import Symbol from '../helpers/Symbol'
import formatValue from '../helpers/formatValue'
import { 
	scopeAtom,
	bookmarksProductsAtom, 
	showBookmarksAtom,
} from '../components/atoms'

import '../styles/Bookmarks.sass'

export default function Bookmarks() {

	const bProducts = useRecoilValue(bookmarksProductsAtom)
	const [scope, setScope] = useRecoilState(scopeAtom)
	const [showBookmarks, setShowBookmarks] = useRecoilState(showBookmarksAtom)

	const handleScope = () => {
		setScope('bookmarks')
		navigate(`/monitors/`, {
			replace: false
		})
	}

	if (!bProducts.length)
		return null

	if (!showBookmarks) {
		return <div id='HiddenBookmarks'>
			<button onClick={() => setShowBookmarks(true)}>
				<Symbol id='left' /><Symbol id='star' /> { bProducts.length } 
			</button>
		</div>
	}

	return <div id='Bookmarks'>

		<div id='bmHeader'>
			<h4 id='bmTitle'>
				<Symbol id='star' /> { bProducts.length } starred
			</h4>

			<button onClick={handleScope} className='bmBtn bmBtnShow'>
				<Symbol id='left' /><Symbol id='up' /> see all
			</button>

			<button onClick={() => setShowBookmarks(false)} className='bmBtn bmBtnHide'>
				hide <Symbol id='right' /><Symbol id='down' />
			</button>

		</div>

		<div id='bmBookmarks'>
			{ bProducts.map(p => 
				<NavLink to={`/monitors/${p.slug}/`} key={p.slug} className='bmBookmark'>
					<GatsbyImage 
						image={getImage(p.image.file)} 
						fadeIn={false} 
						alt={`${p.brand.name} ${p.model}`}
						className='bmThumb'
					/>
					<h4 className='bmModel'>
						<strong>{ p.brand.name }</strong> { p.model }
					</h4>
					<p className='bmInfo'>
						<span>{ formatValue(p.screenSize, '"') }</span> <span>{ formatValue(p.price, '$') }</span>
					</p>
				</NavLink>
			)}
		</div>

	</div>
}
