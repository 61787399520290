
import React from 'react'

import { usePopover } from '../helpers/Popover'

import srcFreeSync from '../images/FreeSync.webp'
import srcFreeSyncPremium from '../images/FreeSync-Premium.webp'
import srcFreeSyncPremiumPro from '../images/FreeSync-Premium-Pro.webp'

const TYPES = {
	'FreeSync': { name: "FreeSync", src: srcFreeSync, score: 1 },
	'FreeSync-Premium': { name: "FreeSync Premium", src: srcFreeSyncPremium, score: 2 },
	'FreeSync-Premium-Pro': { name: "FreeSync Premium Pro", src: srcFreeSyncPremiumPro, score: 5 },
}

export { TYPES }

export default function GSYNCBadge({ 
	type,
}) {

	const { popoverHandlers } = usePopover({ url: 'FreeSync' })

	return <div className='badge'
		{...popoverHandlers}
	>

		<img src={TYPES[type].src} alt={`AMD ${TYPES[type].name}`} />

	</div>
}
