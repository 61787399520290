
import React from 'react'
import { useRecoilValue } from 'recoil'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

import { productsAtom } from './atoms'
import formatValue from '../helpers/formatValue'

import '../styles/Search.sass'


export default function Search() {

	const [query, setQuery] = React.useState('')  
	const products = useRecoilValue(productsAtom)
	const [results, setResults] = React.useState(null)

	const handleClick = event => {
		setQuery('')
	}

	React.useEffect(() => {

		if (!query || query.trim() === '') {
			setResults(null)
			return false
		}

		const results = []

		const reStart = new RegExp(`^${query}`, 'i')
		for (let p of products) {
			if (reStart.test(p.model) || reStart.test(p.brand.name) || reStart.test(p.slug)) {
				results.push(p)
			}
		}

		if (results.length === 0) {
			const reMiddle = new RegExp(` ${query}`, 'i')
			for (let p of products) {
				if (reMiddle.test(p.model) || reMiddle.test(p.brand.name) || reMiddle.test(p.slug)) {
					results.push(p)
				}
			}
		}

		setResults(results)

	}, [query])


	return <form 
		id='SearchForm'
		onSubmit={event => event.preventDefault()}
	>

		<input type='search'
			id='SearchInput'
			value={query}
			onChange={event => setQuery(event.target.value)}		
			// onKeyPress={event => console.log('key')}
			// autoFocus
			placeholder='Search'
			autoComplete='off'
		/>

		{ !!results &&
			<div id='SearchResults'>
				{ results.map(p =>
					<Link 
						key={p.slug}
						className='searchResult'
						to={`/monitors/${p.slug}/`}
						onClick={handleClick}
					>
						<GatsbyImage image={getImage(p.image.file)} 
							fadeIn={false} 
							alt={`${p.brand.name} ${p.model}`}
							className='srThumb'
						/>
						<h4 className='srModel'>
							{ p.brand.name } { p.model }
							<br />
							<small>
								{ formatValue(p.screenSize, '"') }
							</small>
						</h4>
					</Link>
				)}	
			</div>
		}

	</form>
}