
import React from 'react'

import { usePopover } from '../helpers/Popover'

import srcGSYNC from '../images/G-SYNC.webp'
import srcGSYNCultimate from '../images/G-SYNC-Ultimate.webp'
import srcGSYNCcompatible from '../images/G-SYNC-Compatible.webp'

const TYPES = {
	'G-SYNC': { name: 'G-SYNC', src: srcGSYNC, score: 2 },
	'G-SYNC-ULTIMATE': { name: "G-SYNC ULTIMATE", src: srcGSYNCultimate, score: 5 },
	'G-SYNC-Compatible': { name: "G-SYNC Compatible", src: srcGSYNCcompatible, score: 1 },
}

export { TYPES }

export default function GSYNCBadge({ 
	type,
}) {

	const { popoverHandlers } = usePopover({ url: 'G-SYNC' })

	return <div className='badge'
		{...popoverHandlers}
	>

		<img src={TYPES[type].src} alt={`NVIDIA ${TYPES[type].name}`} />

	</div>
}
