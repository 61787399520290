
import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { RecoilRoot } from 'recoil'

import PANELTYPES from '../config/panelTypes'
import FEATURES from '../config/monitorFeatures'
import { 
	productsAtom, 
	formConfigAtom,
	categoryStatsAtom, 
	formValueAtoms,
	formArrAtoms,
} from '../components/atoms'

import '../styles/Layout.sass'


export default function WrapRoot({ 
	children
}) {

	console.log('WrapRoot')
	
	const data = useStaticQuery(graphql`  
		query {
			allMonitor {
				nodes {
					slug
					brand { name }
					model
					price
					width
					height
					thick
					resolutionH
					resolutionV
					aspectRatio
					aspectRatioFloat
					resolutionStandard
					# displayStandards
					screenSize
					refreshRate
					responseTime
					brightness
					contrast
					pixelDensity
					curveRadius
					colorDepth
					frc
					pivot
					backlight
					kvm
					hdr
					gsync
					freesync
					vesa
					speakers
					speakersWatt
					hdcp
					ports
					pbp
					panelTech
					dimensions
					image {
						file {
							childImageSharp {
								gatsbyImageData(
									width: 300
								)
								# fluid(
								# 	maxWidth: 300, 
								# 	# background: "#ffffff",
								# 	traceSVG: {
								# 		turdSize: 50,
								# 		threshold: 250,
								# 	}
								# ) {
								# 	...GatsbyImageSharpFluid_withWebp_tracedSVG
								# }
							}
						}
					}
				}
			}
		}
	`)

	const initState = ({ set }) => {		

		console.log('initState')
		
		set(productsAtom, data.allMonitor.nodes)
		set(formArrAtoms('panelTypes'), Object.keys(PANELTYPES))
		set(categoryStatsAtom, { monitors: { total: data.allMonitor.nodes.length } })

		const config = getFormConfig(data.allMonitor.nodes)
		set(formConfigAtom, config)

		set(formValueAtoms('price'), [config.attrs.price.min, config.attrs.price.max])
		set(formValueAtoms('screenSize'), [config.attrs.screenSize.min, config.attrs.screenSize.max])
		set(formValueAtoms('refreshRate'), [config.attrs.refreshRate.min])
		set(formValueAtoms('responseTime'), [config.attrs.responseTime.max])
		set(formValueAtoms('pixelDensity'), [config.attrs.pixelDensity.min])
		set(formValueAtoms('brightness'), [config.attrs.brightness.min])
		set(formValueAtoms('resolutionV'), [config.attrs.resolutionV.min])
		set(formValueAtoms('aspectRatioFloat'), [config.attrs.aspectRatioFloat.min, config.attrs.aspectRatioFloat.max])
		set(formValueAtoms('contrast'), [config.attrs.contrast.min])
	}

	return <RecoilRoot initializeState={initState}>

		{ children }

	</RecoilRoot>
}



const ALLOWEDPORTS = ["DisplayPort-",
"DisplayPort--1.4--",
"DisplayPort----out",
"mini DisplayPort-",
"HDMI-",
"HDMI--2--",
"USB-A--3--out",
"USB-A--3.2--out",
"USB-B-",
"USB-B--3.2--",
"USB-C-",
"USB-C--3-1.2-",
"USB-C--3-1.2-out",
"audio----out",]


function getFormConfig(products) {

	const attrs = {
		price: {	step: 50 },
		screenSize: { step: 1 },
		refreshRate: {	step: 10 },
		responseTime: { step: 1 },
		resolutionV: {	step: 100 },
		brightness: { step: 50 },
		pixelDensity: { step: 10 },
		contrast: {	step: 100 },
		aspectRatioFloat: { step: 0.1 },
	}

	const features = Object.fromEntries(Object.keys(FEATURES).map(k => [k, 0]))
	// const panelTypes = Object.fromEntries(Object.keys(PANELTYPES).map(k => [k, 0]))
	const panelTypes = {}

	const productCount = products.length

	for (const key of Object.keys(attrs)) {

		const attr = attrs[key]
		attr.min = Infinity
		attr.max = -Infinity
		attr.stats = {}

		for (const node of products) {

			let val = Number.parseFloat(node[key])
			// console.log(key, val, val.toFixed(1))
			if (!val) return
			// if (val % 1 !== 0) 
				// val = Number.parseFloat(val.toFixed(1))

			// console.log(key, val)
	
			if (val < attr.min) 
				attr.min = val
			if (val > attr.max)
				attr.max = val

			let slot = null
			switch (key) {				
				case 'price':
				case 'pixelDensity':
				case 'brightness':
				case 'contrast':
					slot = Math.floor(val/attr.step) * attr.step
					// console.log(key, val, attr.step, slot)
					// if (slot % 1 !== 0)
					// 	slot = Number.parseFloat(slot.toFixed(1))
					break
				case 'screenSize':
					slot = Math.floor(val)
					break
				case 'aspectRatioFloat':
					slot = node.aspectRatio
					break
				default:
					slot = val
			}
			attr.stats[slot] = (attr.stats[slot] || 0) + 1
		}

		switch (key) {

			case 'price':
			case 'screenSize':
			case 'pixelDensity':
			case 'brightness':
			case 'contrast':
				attr.options = Object.entries(attr.stats).map(([val, count]) => {
					return [parseFloat(val), count]
				}).sort((a, b) => a[0] - b[0])
				attr.min = Math.floor(attr.min)
				attr.max = Math.ceil(attr.max)
				attr.options[0][0] = attr.min
				attr.options[attr.options.length-1][0] = attr.max
				break

			case 'aspectRatioFloat':
				attr.options = Object.entries(attr.stats).map(([val, count]) => {
					return [getAspectRatioFloat(val), count, val]
				}).sort((a, b) => a[0] - b[0])
				break

			default:
				attr.options = Object.entries(attr.stats).map(([val, count]) => {
					return [parseFloat(val), count]
				}).sort((a, b) => a[0] - b[0])
		}

		if (key === 'responseTime')
			attr.options.reverse()
	}

	const ports = {}
	// const aspectRatios = 
	for (const node of products) {

		for (const f of Object.keys(features)) {
			if (FEATURES[f].check(node))
				features[f]++
		}

		panelTypes[node.panelTech] = (panelTypes[node.panelTech] || 0) + 1
		
		const maxTypes = {}

		for (const port of node.ports) {

			const name = port.name
			const type = `${name}-${port.attrs?.type ?? ''}`

			if (!ports[type]) {
				ports[type] = {
					name,
					attrs: {},
					slug: port.slug,
					max: 0,
					count: 0,
					variants: {},
				}
			}

			const id = `${type}-${port.attrs.version || ''}-${port.attrs.dp ?? ''}-${port.attrs.out ? 'out' : ''}`
			if (id !== `${type}---`) {
				ports[type].variants[id] = {
					...port,
					max: Math.max(ports[type].variants[id]?.max || 0, port.count || 1),
					count: (ports[type].variants[id]?.count || 0) + 1,
				}
			} else {
				ports[type].count = port.count
			}
			
			maxTypes[type] = (maxTypes[type] || 0) + (port.count || 1)
		}

		for (const [type, typeMax] of Object.entries(maxTypes)) {
			ports[type].max = Math.max(ports[type].max, typeMax)
		}
	}

	for (const id of Object.keys(ports)) {
		const variants = Object.entries(ports[id].variants)
		for (const [k, v] of variants) {
			ports[id].count = ports[id].count + v.count
		}
		if (variants.every(([vid, v]) => v.count === ports[id].count )) {
			ports[id].attrs = variants[0][1].attrs
			delete ports[id].variants
		}
	}

	const portsArr = []
	for (const [id, port] of Object.entries(ports)) {
		if (ALLOWEDPORTS.includes(id)) {
			portsArr.push(getPortType(id, port, false, productCount))	
		}
		if (!!port.variants) {
			for (const [vid, v] of Object.entries(port.variants)) {
				if (ALLOWEDPORTS.includes(vid)) {
					portsArr.push(getPortType(vid, v, true, productCount))
				}
			}
		}
	}
	portsArr.sort((...args) => {
		const compare = args.map((item, idx) => {
			if (/^HDMI/.test(item.id)) {
				return 'A'
			} else if (/^DisplayPort----out/.test(item.id)) {
				return 'D'
			} else if (/^mini DisplayPort/.test(item.id)) {
				return 'E'
			} else if (/^DisplayPort/.test(item.id)) {
				return 'B'
			} else if (/^USB-C/.test(item.id)) {
				return 'U'
			} else if (/^USB-B/.test(item.id)) {
				return 'V'
			} else if (/^USB-A/.test(item.id)) {
				return 'W'
			} else if (/^audio/.test(item.id)) {
				return 'Z'
			}
			// if (item.port.slug === 'audio') {
			// 	return 'Z'
			// } else if (item.port.slug === 'mini-DisplayPort') {
			// 	return 'DisplayPortZ'
			// } else {
			// 	return `${item.port.slug}-${item.port.attrs.out ? 'z' : 'a' }-${item.port.version ?? ''}`
			// }
		})
		return `${compare[0]}`.localeCompare(`${compare[1]}`, 'en')
	})

	for (const key of Object.keys(features)) {
		features[key] = Math.round((features[key] / productCount)*100)
	}
	for (const pt of Object.keys(panelTypes)) {
		panelTypes[pt] = Math.round((panelTypes[pt] / productCount)*100)
	}

	return { 
		attrs, 
		ports: portsArr,
		features,
		panelTypes,
	}
}


function getPortType(id, port, sub = false, productCount) {

	const obj = {
		id,
		max: port.max,
		sub,
		count: port.count,
		pc: Math.round((port.count / productCount)*100),
		port: {
			slug: port.slug,
			name: port.name,
			attrs: Object.entries(port.attrs).reduce((agg, [key, val]) => {
				if (!!val && key !== 'hdcp') {
					agg[key] = val
				}
				return agg
			}, {})
		}
	}

	// if (Object.keys(obj.port.attrs).length < 1)
		// delete obj.port.attrs

	return obj
}


function getAspectRatioFloat(string) {
	const vals = string.split(':')
	return Number.parseFloat((vals[0]/vals[1]).toFixed(1))
}