
import React from 'react'
import loadable from '@loadable/component'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import useResizeObserver from 'use-resize-observer'

import { popoverAtom } from '../components/atoms'

import '../styles/Popover.sass'


export function usePopover(config = {}) {

	const [popover, setPopover] = useRecoilState(popoverAtom)
	const defaultUrl = config.url

	const onMouseEnter = event => {

		let target = event.target
		let url = defaultUrl ?? target.getAttribute('data-popover')
		if (!url) {
			target = event.target.parentNode
			url = target.getAttribute('data-popover')
		}
		if (!url) return

		const rect = target.getBoundingClientRect()
		const top = rect.top + window.scrollY - 15
		let left = rect.left + rect.width + 5
		if (config.formSide) {
			const formRect = document.getElementById('MonitorForm').getBoundingClientRect()
			left = formRect.left + formRect.width
		}

		setPopover({
			url,
			attrs: config.attrs ?? null,
			top,
			left,
		})
	}

	const onMouseLeave = () => {
		setPopover(null)
	}

	return { 
		popover, 
		popoverHandlers: {
			onMouseEnter,
			onMouseLeave,
		}
	}
}


const LazySnippet = loadable(props => import(`../snippets/${props.url}`), {
	fallback: <div id='PopoverLoading'>Loading...</div>,
	cacheKey: props => props.url
})


export function PopoverHandler() {
	const config = useRecoilValue(popoverAtom)
	return config
		? <Popover config={config} />
		: null
}


export function Popover({ config }) {

	const ref = React.useRef(null)
	const [offset, setOffset] = React.useState({ top: 0, left: 0 })
	const setPopover = useSetRecoilState(popoverAtom)

	useResizeObserver({
		ref,
		onResize: () => {
			if (!ref?.current) return
			const div = ref.current.getBoundingClientRect()
			let offset = { top: 0, left: 0 }
			if (div.bottom > window.innerHeight) {
				offset.top = window.innerHeight - div.bottom - 10
			}
			if (div.right > window.innerWidth) {
				offset.left = window.innerWidth - div.right
			}
			setOffset(offset)
		}
	})

	const onMouseEnter = () => {
		setPopover(config)
	}

	const onMouseLeave = () => {
		setPopover(null)
	}

	return <div id='Popover'
		key={config.url}
		ref={ref}
		onMouseEnter={onMouseEnter}
		onMouseLeave={onMouseLeave}
		style={{
			top: `${config.top + offset.top}px`,
			left: `${config.left + offset.left}px`,
		}}
	>
		<LazySnippet 
			url={config.url} 
			attrs={config.attrs}
		/>
	</div>
}
