
import React from 'react'
// import { Helmet } from 'react-helmet'

import Navbar from './Navbar'
import Bookmarks from './Bookmarks'
import { PopoverHandler } from '../helpers/Popover'
import Sprite from './Sprite'
import PortSprite from './PortSprite'
// import Footer from './Footer'


export default function WrapPage({ 
	children
}) {

	return <>

		<Navbar />

		{ children }

		<Bookmarks />

		{/* <Footer /> */}
		
		<PopoverHandler />
		<Sprite />
		<PortSprite />

	</>
}



// { IS_PROD && <>
// 	<script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`} />
// 	<script dangerouslySetInnerHTML={{ __html: `
// window.dataLayer = window.dataLayer || [];
// function gtag(){dataLayer.push(arguments);}
// gtag('js', new Date());
// gtag('config', '${GA_TRACKING_ID}', {
// page_path: window.location.pathname,
// });`
// 		}}
// 	/>
// </> }