
import { atom, selector, DefaultValue, atomFamily } from 'recoil'

import SORTATTRS from '../config/sortAttributes'
import FEATURES from '../config/monitorFeatures'


export const scopeAtom = atom({
	key: `scope`,
	default: 'form',
})

export const viewAtom = atom({
	key: 'view',
	default: 'grid',
})

export const popoverAtom = atom({
	key: `popover`,
	default: null,
})


// Compare

export const compareStartAtom = atom({
	key: `compareStart`,
	default: 0
})

export const compareColumnsAtom = atom({
	key: `compareColumns`,
	default: 4
})

export const compareKeepersAtom = atom({
	key: `compareKeepers`,
	default: []
})


// Chart

export const chartThumbsAtom = atom({
	key: `chartThumbs`,
	default: false
})

export const chartSizeScaleAtom = atom({
	key: `chartSizeScale`,
	default: 6
})

export const chartScalesAtom = atom({
	key: `chartScales`,
	default: {
		y: 'price',
		x: 'screenSize',
		s: 'screenSize',
		c: 'responseTime',
	}
})



// Bookmarks

export const showBookmarksAtom = atom({
	key: 'showBookmarks',
	default: true,
})

export const bookmarksAtom = atom({
	key: `bookmarks`,
	default: [],
	effects_UNSTABLE: [
		localStorageEffect
	]
})

function localStorageEffect({ setSelf, onSet }) {
	if (typeof localStorage === 'undefined')
		return
	const savedValue = localStorage.getItem('bookmarks')
	if (savedValue != null) {
		setSelf(JSON.parse(savedValue))
	}
	onSet(newValue => {
		if (newValue instanceof DefaultValue) {
			localStorage.removeItem('bookmarks')
		} else {
			localStorage.setItem('bookmarks', JSON.stringify(newValue))
		}
	})
}

export const bookmarksProductsAtom = selector({
	key: 'bookmarksProducts',
	get: ({ get }) => {
		const products = get(productsAtom)
		const bookmarks = get(bookmarksAtom)
		return bookmarks.reduce((aggr, slug) => {
			const product = products.find(p => p.slug === slug)
			if (!!product)
				aggr.push(product)
			return aggr
		}, [])
	}
})



// Form

export const showFormAtom = atom({
	key: 'showForm',
	default: true,
})

export const formConfigAtom = atom({
	key: 'formConfigAtom',
	default: {},
})

export const formValueAtoms = atomFamily({
	key: 'FormValue',
	default: [0, 1],
})

export const formMapAtoms = atomFamily({
	key: 'FormMap',
	default: {},
})

export const formArrAtoms = atomFamily({
	key: 'FormArr',
	default: [],
})

export const sortOrderAtom = atom({
	key: `sortOrder`,
	default: 'max',
})
export const sortAttrAtom = atom({
	key: `sortAttr`,
	default: 'screenSize',
})

// Products 

export const productsAtom = atom({
	key: `products`,
	default: []
})

export const filteredProductsAtom = selector({
	key: 'filteredProducts',
	get: ({ get }) => {

		const scope = get(scopeAtom)

		const sortAttr = get(sortAttrAtom)
		const sortOrder = get(sortOrderAtom)
		const sortFunc = (a, b) => sortOrder === 'min'
			? a[sortAttr] - b[sortAttr]
			: b[sortAttr] - a[sortAttr]

		if (scope === 'bookmarks') {

			const bProducts = get(bookmarksProductsAtom)
			return [...bProducts].sort(sortFunc)
			
		} else {

			const fProducts = get(productsAtom)
			
			const price = get(formValueAtoms('price'))
			const screenSize = get(formValueAtoms('screenSize'))
			const refreshRate = get(formValueAtoms('refreshRate'))
			const responseTime = get(formValueAtoms('responseTime'))
			const pixelDensity = get(formValueAtoms('pixelDensity'))
			const resolutionV = get(formValueAtoms('resolutionV'))
			const brightness = get(formValueAtoms('brightness'))
			const aspectRatioFloat = get(formValueAtoms('aspectRatioFloat'))
			const contrast = get(formValueAtoms('contrast'))
			const ports = get(formMapAtoms('ports'))
			const features = get(formArrAtoms('features'))
			const panelTypes = get(formArrAtoms('panelTypes'))

			// console.log(fProducts)
			// console.log(price)
			// for (let p of fProducts) {
				// if (!(p.price <= price[1] || p.price >= price[1]))
					// console.log(p)
				// console.log(p.slug, p.price, (p.price >= price[0] && p.price <= price[1]))
			// }

			return fProducts.filter(p => (
				p.price >= price[0] 
					&& p.price <= price[1] 
				&& p.screenSize >= screenSize[0] 
					&& p.screenSize <= (screenSize[1] + 0.9)
				&& p.refreshRate >= refreshRate[0]
				&& p.responseTime <= responseTime[0]
				&& p.pixelDensity >= pixelDensity[0]
				&& p.brightness >= brightness[0]
				&& p.resolutionV >= resolutionV[0]
				&& p.contrast >= contrast[0]
				&& p.aspectRatioFloat >= aspectRatioFloat[0] 
					&& p.aspectRatioFloat <= aspectRatioFloat[1]
				&& checkPorts(ports, p)
				&& checkFeatures(features, p)
				&& checkPanelTypes(panelTypes, p)
			)).sort(sortFunc)
		}
	}
})

const checkPorts = (ports, p) => {
	if (Object.keys(ports).length === 0)
		return true
	return !Object.values(ports).some(type =>
		type.min > p.ports.reduce((count, port) => {
			if (port.name !== type.name
				|| (type.attrs.version && port.attrs.version !== type.attrs.version)
				|| (type.attrs.type && port.attrs.type !== type.attrs.type)
				|| (type.attrs.out && !port.attrs.out)
			) {
				return count
			} else {
				return count + (port.count || 1)
			}
		}, 0)
	)
}

const checkFeatures = (features, p) =>
	features.length
		? !features.some(id => {
			return !(FEATURES[id].check(p))
		})
		: true

const checkPanelTypes = (panelTypes, p) =>
	panelTypes.includes(p.panelTech)


	
// Stats

const STATATTRS = [ ...Object.keys(SORTATTRS), 'height', 'width' ]

export const categoryStatsAtom = atom({
	key: `categoryStats`,
	default: { monitors: { total: 0 } }
})

export const productsStatsAtom = selector({
	key: 'productsStats',
	get: ({ get }) => {

		const products = get(filteredProductsAtom)
		const stats = {
			total: products.length,
		}

		for (const p of products) {
			for (const attr of STATATTRS) {
				stats[`sum${attr}`] = (stats[`sum${attr}`] || 0) + p[attr]
				stats[`min${attr}`] = (stats[`min${attr}`] || Infinity) > p[attr] ? p[attr] : stats[`min${attr}`]
				stats[`max${attr}`] = (stats[`max${attr}`] || -Infinity) < p[attr] ? p[attr] : stats[`max${attr}`]
			}
		}

		for (const attr of STATATTRS) {
			stats[`avg${attr}`] = Math.round(stats[`sum${attr}`] / stats.total)
		}
		return stats
	}
})
