
export default {
	price: { label: 'price', default: 'min' },
	screenSize: { label: 'screen size', default: 'max' },
	resolutionV: { label: 'resolution', default: 'max' },
	aspectRatioFloat: { label: 'aspect ratio', default: 'max' },
	refreshRate: { label: 'refresh rate', default: 'max' },
	responseTime: { label: 'response time', default: 'min' },
	brightness: { label: 'brightness', default: 'max' },
	pixelDensity: { label: 'pixel density', default: 'max' },
	contrast: { label: 'contrast', default: 'max' },
}
