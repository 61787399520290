
import React from 'react'

import { usePopover } from '../helpers/Popover'

import HDR10 from '../images/HDR10.svg'
import HDR400 from '../images/DisplayHDR-400.png'
import HDR500 from '../images/DisplayHDR-500.png'
import HDR600 from '../images/DisplayHDR-600.png'
import HDR1000 from '../images/DisplayHDR-1000.png'
import HDR1400 from '../images/DisplayHDR-1400.png'
import HDRtb400 from '../images/DisplayHDR-400-TrueBlack.png'
import HDRtb500 from '../images/DisplayHDR-500-TrueBlack.png'


const TYPES = {
	'HDR10': { name: "HDR 10", score: 1 }, 
	'DisplayHDR-400': { name: "DisplayHDR 400", src: HDR400, score: 2 },
	'DisplayHDR-500': { name: "DisplayHDR 500", src: HDR500, score: 3 },
	'DisplayHDR-600': { name: "DisplayHDR 600", src: HDR600, score: 5 },
	'DisplayHDR-1000': { name: "DisplayHDR 1000", src: HDR1000, score: 6 },
	'DisplayHDR-1400': { name: "DisplayHDR 1400", src: HDR1400, score: 7 },
	'DisplayHDR-400-TrueBlack': { name: "DisplayHDR TrueBlack 400", src: HDRtb400, score: 10 },
	'DisplayHDR-500-TrueBlack': { name: "DisplayHDR TrueBlack 500", src: HDRtb500, score: 11 },
}

export { TYPES }


export default function HDRBadge({ 
	type,
}) {

	const { popoverHandlers } = usePopover({ url: 'HDR' })

	return <div className='badge badgeHDR'
		{...popoverHandlers}
	>
		{ type === 'HDR10'
			?	<HDR10 />
			:	<img src={TYPES[type].src} alt={`Vesa ${TYPES[type].name}`} />
		}
	</div>
}
